var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tasks
    ? _c(
        "div",
        { staticClass: "synology-download-wrapper" },
        _vm._l(_vm.tasks, function (task, key) {
          return _c(
            "div",
            { key: key, staticClass: "task-row" },
            [
              _c("PercentageChart", {
                attrs: {
                  title: task.DisplayTitle,
                  showAsPercent: false,
                  showLegend: false,
                  values: [
                    {
                      label: _vm.$t("widgets.synology-download.downloaded"),
                      size: task.Progress,
                      color: "#20e253",
                    },
                    {
                      label: _vm.$t("widgets.synology-download.remaining"),
                      size: 100 - task.Progress,
                      color: "#6092d1",
                    },
                  ],
                },
              }),
              _c("p", { staticClass: "info" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$t("widgets.synology-download.downloaded"))
                  ),
                ]),
                _vm._v(
                  ": " +
                    _vm._s(_vm._f("formatSize")(task.Downloaded)) +
                    " / " +
                    _vm._s(_vm._f("formatSize")(task.TotalSize)) +
                    " (" +
                    _vm._s(task.Progress) +
                    "%) (" +
                    _vm._s(_vm._f("formatSize")(task.DownSpeed)) +
                    "/s)"
                ),
                _c("br"),
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("widgets.synology-download.uploaded"))),
                ]),
                _vm._v(
                  ": " +
                    _vm._s(_vm._f("formatSize")(task.Uploaded)) +
                    " (" +
                    _vm._s(_vm._f("formatSize")(task.UpSpeed)) +
                    "/s) (ratio : " +
                    _vm._s(
                      Math.floor((task.Uploaded / task.Downloaded) * 100) / 100
                    ) +
                    ") "
                ),
              ]),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }